import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHydrated } from 'remix-utils/use-hydrated';
import ButtonDeprecated from './ButtonDeprecated';

type CloseOrigin = 'undo' | 'timeout' | 'close';

export interface NotificationProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'> {
  show: boolean;
  type?: 'error';
  innerKey: string;
  withUndo?: boolean;
  description: string;
  onUndo?: () => void;
  persistent?: boolean;
  durationInMs?: number;
  close: (from: CloseOrigin) => void;
  customAction?: (close: () => void) => JSX.Element;
  onCloseComplete?: (closeOrigin?: CloseOrigin) => void;
}

const Notification: FC<NotificationProps> = ({
  description,
  onUndo,
  type,
  persistent,
  onCloseComplete,
  durationInMs = 5000,
  show,
  className,
  close,
  innerKey,
  customAction,
  withUndo,
}) => {
  const { t } = useTranslation();
  const [closeOrigin, setCloseOrigin] = useState<CloseOrigin>();

  useEffect(() => {
    if (!persistent && show) {
      const timer = setTimeout(() => {
        close('timeout');
        setCloseOrigin('timeout');
      }, durationInMs);
      return () => clearTimeout(timer);
    }
  }, [persistent, show, durationInMs]);

  const hydrated = useHydrated();
  if (!hydrated) {
    return null;
  }

  return createPortal(
    <AnimatePresence onExitComplete={() => onCloseComplete?.(closeOrigin)}>
      {show && (
        <motion.div
          layout
          key={innerKey}
          initial={{ opacity: 0, y: -10 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ ease: 'easeInOut', duration: 0.25 }}
          aria-live="assertive"
          className={clsx('pointer-events-none w-max', className)}
        >
          <div className="flex w-full flex-col items-center space-y-4">
            <div className="dark:shadow-classic shadow-lightModeClassic pointer-events-auto w-full min-w-[513px] overflow-hidden rounded-xl border-b border-[#0D0E0E] bg-darkMode-gray-dark ring-1 ring-background-900 ring-opacity-5">
              <div
                className={clsx(
                  'py-[15px] px-6',
                  type === 'error' &&
                    'bg-darkMode-danger/20 [&_button]:!text-darkMode-darkMode-danger [&_.notification-text]:!text-darkMode-danger [&_svg]:!text-darkMode-danger'
                )}
              >
                <div className="flex items-center">
                  <div className="flex flex-1 items-center justify-between">
                    <p className="notification-text flex-1 text-base font-medium text-darkMode-gray-light">
                      {description}
                    </p>
                    {(onUndo || withUndo) && (
                      <ButtonDeprecated
                        onClick={() => {
                          onUndo?.();
                          close('undo');
                          setCloseOrigin('undo');
                        }}
                        themeType="link"
                        className="ml-4 flex-shrink-0 rounded-md  !p-0 !text-base font-bold text-primary hover:text-primary/80"
                      >
                        {t('undo')}
                      </ButtonDeprecated>
                    )}
                  </div>
                  {customAction && customAction(() => close('close'))}
                  <div className="ml-4 flex flex-shrink-0">
                    <ButtonDeprecated
                      themeType="link"
                      className="inline-flex rounded-md  !p-0 text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        close('close');
                        setCloseOrigin('close');
                      }}
                    >
                      <span className="sr-only">{t('close')}</span>
                      <XIcon
                        className="h-5 w-5 text-primary"
                        aria-hidden="true"
                      />
                    </ButtonDeprecated>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById('notification-portal') as HTMLDivElement
  );
};

export default Notification;
